.hero {
  position: relative;
  aspect-ratio: 8 / 2.5;
  background-color: $color-dark;
  @media screen and (max-width: 1199px) {
    aspect-ratio: 8 / 5;
  }
  @media screen and (max-width: 991px) {
    aspect-ratio: 8 / 7;
  }
  @media screen and (max-width: 767px) {
    aspect-ratio: unset;
    height: 50vh;
  }
  &__overlay {
    position: absolute;
    z-index: 1;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.43);
  }

  &__container {
    position: relative;
    display: flex;
    z-index: 2;
    height: 100%;
    align-items: flex-end;
  }

  &__content {
    margin-bottom: $space-2;
  }

  &__bg {
    position: absolute;
    inset: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title {
    color: $color-white;
    font-size: $display-2;
    font-weight: $font-weight-bold;
    margin-bottom: calc($space-1 / 2);
    width: 65%;
    position: relative;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -$font-size;
      left: 0;
      width: 78px;
      height: 6px;
      background: $color-secondary;
    }
  }
  &__subtitle {
    color: $color-trans;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  &__actions {
    display: flex;
    gap: calc($space-1 / 2);
  }
}

.clientes {
  padding: $space-2 0;
  &__container {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
  }
}

.actividades {
  padding: $space-4 0 $space-4;
  @media screen and (max-width: 991px) {
    padding: $space-3 0 $space-3;
  }

  &__items {
    display: flex;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      gap: $space-1;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__item {
    flex: 1;
    @media screen and (max-width: 767px) {
      display: flex;
      gap: $space-1;
    }
  }

  &__icono {
    margin-bottom: $space-1;
    @media screen and (max-width: 991px) {
      flex-basis: 50px;
    }
    img {
      height: 40px;
      width: auto;
      @media screen and (max-width: 767px) {
        margin-bottom: $font-size-smaller;
        width: 50px;
        height: auto;
      }
    }
  }
  &__info {
    @media screen and (max-width: 991px) {
      flex: 3;
    }
  }
  &__titulo {
    color: $color-dark;
    font-weight: $font-weight-bold;
    font-size: $header-3;
    @media screen and (max-width: 991px) {
      font-size: $header-4;
    }
  }
  &__descripcion {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}

.break {
  // padding: $space-3 0;
  display: flex;
  gap: $space-1;

  &__cover {
    height: 512px;
    @media screen and (max-width: 991px) {
      height: 270px;
    }
    &:first-child {
      flex: 2;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    &:last-child {
      flex: 1;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.claim {
  padding: $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }
  &__container {
    width: 70%;
    margin-left: auto;
    line-height: 1.7;
    @media screen and (max-width: 991px) {
      width: 100%;
      margin-left: auto;
      line-height: 1.3;
    }
    &--left {
      width: 80%;
      margin-right: auto;
      margin-left: 0;
    }
  }

  &__titulo {
    font-size: $header-1;
    margin-bottom: $space-1;
    font-weight: $font-weight-medium;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      line-height: 1.6;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
}

.especialistas {
  padding: $space-5 0;
  background-color: $color-primary;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }
  &--mb {
    margin-bottom: $space-1;
  }
  &__titulo {
    font-size: $display-1;
    color: $color-white;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
      margin-bottom: $space-1;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      gap: $font-size;
    }
    .boton {
      @media screen and (max-width: 767px) {
        flex: 1;
      }
    }
  }

  &__action {
  }
}

.proyectos {
  padding: $space-3 0;
  @media screen and (max-width: 991px) {
    padding: $space-2 0;
  }
}

.spro {
  &__items {
  }
  &__item {
    padding-left: calc($font-size / 2);
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }
  &__dots {
    // width: 100%;
    position: relative;
    .slick-dots {
      position: relative;
      bottom: initial !important;
    }
  }
  &__actions {
    display: flex;
    gap: $space-1;
    align-items: center;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $font-size;
    }
  }
}

.spro-item {
  &__imagen {
    margin-bottom: $font-size;
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 8/5;
    }
  }

  &__title {
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: $header-3;
      color: $color-dark;
      @media screen and (max-width: 767px) {
        font-size: $header-5;
      }
    }
  }

  &__subtitle {
    color: $color-grey-4;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
}
