.cproyecto {
  padding: $space-4 0;
  &_content {
    display: flex;
    gap: $space-4;
  }

  &__left {
    flex: 1.7;
  }

  &__imagen {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    flex: 1;
  }

  &__info {
  }

  &__actions {
    display: flex;
    gap: $font-size;
  }
}

.info {
  &__pretitulo {
    font-size: $header-4;
    color: $color-primary-dark;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
  }

  &__destacado {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    margin-bottom: $space-4;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: calc($font-size * -1.3);
      left: 0;
      width: 78px;
      height: 4px;
      background: $color-primary;
    }
  }

  &__info {
    margin-bottom: $space-2;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: calc($font-size * -3);
      left: 0;
      width: 78px;
      height: 4px;
      background: $color-primary;
    }
  }
}
