.contacto {
  padding: $space-3 0 0 0;
  @media screen and (max-width: 991px) {
    padding: $space-2 0 0 0;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-bottom: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  &__info {
  }

  &__content {
    padding-top: $space-1;
  }

  &__item {
    margin-bottom: $space-1;
    position: relative;
    a {
      text-decoration: none;
      color: $color-dark;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc($space-2 * 1.6);
      left: 0;
      width: 80%;
      height: 1px;
      background: $color-grey-5;
    }

    &:nth-last-child(1)::after {
      top: calc($space-3 * 1.8);
    }
  }

  &__email {
  }

  &__subtitulo {
    color: $color-primary-dark;
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
  }

  &__telefono {
  }

  &__direccion {
  }

  &__form {
  }

  &__form-content {
    padding: calc($header-1/2);
    background-color: $color-grey-6;
    border-radius: 15px;
  }

  &__ubicacion {
    background-color: $color-primary-dark;
    iframe {
      width: 100%;
    }
  }
}

.formulario {
}
