.cta {
  padding: $space-3 0 $space-5 0;
  @media screen and (max-width: 991px) {
    padding: $space-1 0 $space-3 0;
  }
  &__titulo {
    font-size: $display-3;
    color: $color-dark;
    margin-bottom: $space-1;
    font-weight: $font-weight-medium;
    @media screen and (max-width: 991px) {
      font-size: $header-2;
    }
  }

  &__subtitulo {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    color: $color-primary;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      font-size: $font-size;
      margin-bottom: $space-1;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      gap: $font-size;
    }
    .boton {
      @media screen and (max-width: 767px) {
        flex: 1;
      }
    }
  }
}

.cta-ecommerce {
  background-color: $color-grey-6;
  padding: $space-2 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__titulo {
  }

  &__actions {
  }
}
