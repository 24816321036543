.page-title {
  padding: $space-4 0 $space-2 0;
  background-color: $color-primary-dark;
  position: relative;
  @media screen and (max-width: 991px) {
    padding: $space-2 0 $space-1 0;
  }
  &__h {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-secondary;
    @media screen and (max-width: 991px) {
      font-size: $header-4;
    }
  }
  &__sub {
    font-size: $display-3;
    color: $color-white;
    font-weight: $font-weight-bold;
    width: 48%;
    line-height: 1.2;
    @media screen and (max-width: 991px) {
      font-size: 2rem;
      width: 100%;
    }
  }
  &__breadcrumbs {
    color: $color-white;
    font-size: $font-size-smaller;
    a {
      color: $color-white;
      text-decoration: none;
      font-weight: $font-weight-bold;
    }
  }
  &__overlay {
    inset: 0;
    position: absolute;
    z-index: 1;
    background: rgba($color: $color-primary-dark, $alpha: 0.6);
  }
  &--background {
    position: relative;
    aspect-ratio: 16 / 4;
  }
  &__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__container {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}
