.proyectos-realizados {
  padding-top: $space-4;
  @media screen and (max-width: 991px) {
    padding-top: $space-2;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $space-3 $space-1;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
      gap: $space-2 $space-1;
    }
  }
}
