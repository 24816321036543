.presupuesto {
  padding: $space-3 0;
  @media screen and (max-width: 991px) {
    padding: $space-2 0;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__titulo {
    font-size: $header-1;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
      margin-bottom: $font-size;
    }
  }

  &__info {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  &__form {
  }
}
