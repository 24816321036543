.footer {
  padding: $space-3 0 $space-1;
  background-color: $color-primary-dark;
  @media screen and (max-width: 991px) {
    padding: $space-2 0 $space-1;
  }
  &__container {
  }
  &__titulo {
    color: $color-white;
    font-size: $header-3;
    font-weight: bold;
    margin-bottom: $font-size;
  }

  &__grid {
    margin-bottom: $space-2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(3, 1fr);
      gap: calc($font-size * 2);
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-1;
      text-align: center;
      padding-bottom: $space-2;
      margin-bottom: $space-1;
    }
  }

  &__item {
    &--logo {
      @media screen and (max-width: 1199px) {
        grid-column: 1 / 4;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1/ 1;
        align-items: center;
      }
      img {
        width: 100%;
        height: auto;
        max-width: 200px;
      }
    }
    &--instarec {
    }
    &--servicios {
      ul.menu {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li.menu-item a {
          text-decoration: none;
          color: $color-white;
          &:hover {
            color: $color-white;
          }
        }
      }
    }
    &--rs {
    }
    p {
      color: $color-white;
    }
    a {
      text-decoration: none;
      color: $color-white;
      &:hover {
        color: $color-white;
      }
    }
  }

  &__copy {
    display: flex;
    justify-content: space-between;
    color: $color-white;
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    > * {
      color: $color-white;
    }
    a {
      text-decoration: none;
      color: $color-white;
    }
  }
  .widget-footer__title {
    color: $color-white;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
}
.nav-footer {
  color: $color-white;
  &__items {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
  li.menu-item {
    margin-right: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: calc($font-size/2);
    }
    a {
      color: $color-trans;
      font-weight: $font-weight-medium;
      text-decoration: none;
      margin: 0;
    }
  }
}

.contactos {
  display: flex;
  gap: $space-1;

  &__item {
    a {
      display: block;
      text-decoration: none;
      color: $color-trans;
      font-weight: $font-weight-normal;
    }
  }

  &__output {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  &__titulo {
    color: $color-white;
    font-weight: bold;
    margin-bottom: $font-size-smaller;
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    // flex-direction: column;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
