.nav-primary {
  display: flex;
  align-items: center;

  &__items {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0px;

    li.menu-item {
      a {
        color: $color-dark;
        font-weight: $font-weight-medium;
        text-decoration: none;
        padding: 0 $font-size-smaller;
      }
      &:nth-last-child(1) {
        margin-left: $font-size;
        a {
          @include button;
          background-color: $color-primary;
          border-color: $color-primary;
          color: $color-white;
          &:hover {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-white;
            box-shadow: $box-shadow-small;
            transform: translateY(-2px);
            transition: $trans;
          }
        }
      }
    }
  }
}

.nav-off {
  margin-bottom: $space-1;

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;

    li.menu-item {
      // margin-bottom: 5px;
      a {
        font-size: $header-2;
        color: $color-white;
        font-weight: $font-weight-bold;
        text-decoration: none;
      }
      &:nth-last-child(1) {
        margin-top: $font-size;
        a {
          @include button;
          background-color: $color-primary;
          border-color: $color-primary;
          color: $color-white;
          &:hover {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-white;
            box-shadow: $box-shadow-small;
            transform: translateY(-2px);
            transition: $trans;
          }
        }
      }
    }
  }
}

.offcanvas {
  &-title {
    font-size: $font-size-smaller;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    letter-spacing: 1px;
  }
  &-logo {
    img {
      width: 100px;
      height: auto;
    }
  }
}
