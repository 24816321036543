.singleproyecto {
  padding-top: $space-4;
  padding-bottom: $space-3;
  @media screen and (max-width: 991px) {
    padding-top: $space-2;
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__cover {
    flex: 1.7;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    flex: 1;
  }

  &__titulo {
    font-weight: $font-weight-bold;
    position: relative;
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: calc($space-2 * 1.5);
      left: 0;
      width: 78px;
      height: 4px;
      background: $color-primary;
    }
  }

  &__descripcion {
    position: relative;
    margin-bottom: $space-3;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -($font-size * 2);
      left: 0;
      width: 100%;
      height: 1px;
      background: $color-primary-dark;
    }
  }

  &__data {
  }
  &__item {
    display: flex;
    gap: $space-1;
  }
  &__subtitulo {
    color: $color-primary-dark;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    flex: 0.5;
    span {
      display: block;
      margin-bottom: $space-1;
      padding-top: $space-2;
      @media screen and (max-width: 991px) {
        margin-bottom: 0px;
        padding-top: 0px;
      }
    }
  }
  &__info {
    font-size: $font-size-small;
    margin-bottom: calc($space-1/3);
    flex: 1;
  }
}

.galeria-proyectos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  &__container {
    margin-bottom: $space-2;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-1;
    }
  }

  &__items {
  }

  &__item {
    aspect-ratio: 8 / 6;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
