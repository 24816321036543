.servicios {
  padding: $space-4 0 $space-2 0;
  @media screen and (max-width: 991px) {
    padding: $space-2 0 $space-1 0;
  }
  &:last-child {
    padding: 0;
  }
  &__items {
  }
}

.thumb-servicio {
  display: flex;
  gap: $space-4;
  margin-bottom: $space-4;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: $space-1;
    margin-bottom: $space-4;
  }
  @media screen and (max-width: 767px) {
    font-size: $font-size-small;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__cover {
    flex: 1.7;
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }
  }

  &__right {
    flex: 1;

    @media screen and (max-width: 991px) {
      padding-top: 0;
    }
  }

  &__titulo {
    margin-bottom: $space-1;
    font-weight: $font-weight-bold;
    color: $color-primary-dark;
    font-size: $header-2;
    @media screen and (max-width: 991px) {
      margin-bottom: calc($space-1/2);
    }
    a {
      text-decoration: none;
      font-weight: $font-weight-bold;
      color: $color-primary-dark;
      font-size: $header-2;
    }
  }

  &__descripcion {
  }
}
